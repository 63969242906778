<template>
  <div>
    <OrgDashboard v-if="currentOrgId" />

    <template v-else-if="$hasPermission('dashboard.operation')">
      <OnlineUsers class="mb-3" />
      <OnlineUv class="mb-3" />
      <GlobalUv class="mb-3" />
      <Heatmap class="mb-3" />
      <UvChart class="mb-3" />
      <ActivityChart class="mb-3" />
      <UvAlerts class="mb-3" />
      <ScreenAlerts class="mb-3" />
    </template>
  </div>
</template>

<script>
import OnlineUsers from '@/pages/base/components/OnlineUsers'
import ActivityChart from '@/pages/base/components/ActivityChart'
import Heatmap from '@/pages/base/components/Heatmap'
import GlobalUv from '@/pages/base/components/GlobalUv'
import UvChart from '@/pages/base/components/UvChart'
import OnlineUv from '@/pages/base/components/OnlineUv'
import UvAlerts from '@/pages/base/components/UvAlerts'
import ScreenAlerts from '@/pages/base/components/ScreenAlerts'
import OrgDashboard from '@/pages/ORG/base/Dashboard'
import {mapState} from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    OnlineUsers,
    Heatmap,
    GlobalUv,
    ScreenAlerts,
    ActivityChart,
    OnlineUv,
    UvChart,
    UvAlerts,
    OrgDashboard
  },
  computed: {
    ...mapState(['currentOrgId'])
  }
}
</script>
