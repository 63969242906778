<template>
  <div :key="currentOrgId">
    <template v-if="$hasPermission('org.dashboard.operation')">
      <OnlineUsers class="mb-3" />
      <OnlineUv class="mb-3" />
      <GlobalUv class="mb-3" />
      <Heatmap class="mb-3" />
      <UvChart class="mb-3" />
      <ActivityChart class="mb-3" />
      <UvAlerts class="mb-3" />
      <ScreenAlerts class="mb-3" />
    </template>
  </div>
</template>

<script>
import OnlineUsers from '@/pages/ORG/base/components/OnlineUsers'
import GlobalUv from '@/pages/ORG/base/components/GlobalUv'
import Heatmap from '@/pages/ORG/base/components/Heatmap'
import OnlineUv from '@/pages/ORG/base/components/OnlineUv'
import ScreenAlerts from '@/pages/ORG/base/components/ScreenAlerts'
import ActivityChart from '@/pages/ORG/base/components/ActivityChart'
import UvChart from '@/pages/ORG/base/components/UvChart'
import UvAlerts from '@/pages/ORG/base/components/UvAlerts'
import {mapState} from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    OnlineUsers,
    ActivityChart,
    UvChart,
    GlobalUv,
    Heatmap,
    OnlineUv,
    ScreenAlerts,
    UvAlerts
  },
  computed: {
    ...mapState(['currentOrgId'])
  }
}
</script>
