<template>
  <div ref="wrapper">
    <div ref="chart" class="vue-gauge-item"></div>
  </div>
</template>
<script>
import * as GaugeChart from 'gauge-chart'
import debounce from 'lodash/debounce'
export default {
  name: 'vue-gauge',
  props: {
    value: {type: Number, default: 0},
    options: {type: Object, default: () => ({})},
    min: {type: Number, default: 0},
    max: {type: Number, default: 100}
  },
  data() {
    return {
      wrapperWidth: 999999,
      gaugeChart: null
    }
  },
  mounted() {
    this.renderChart()
    this.updateDimension()
    window.addEventListener('resize', debounce(this.updateDimension, 500))
  },
  beforeDestroy() {
    window.removeEventListener('resize', debounce(this.updateDimension, 500))
  },
  computed: {
    needleValue() {
      return (this.value * 100) / (this.max - this.min)
    },
    chartWidth() {
      const widthItems = [+this.config.chartWidth]
      if (this.wrapperWidth) {
        widthItems.push(this.wrapperWidth)
      }
      return Math.min(...widthItems)
    },
    config() {
      const defaultConfig = {
        hasNeedle: true,
        needleColor: '#f76c57',
        needleUpdateSpeed: 1000,
        arcColors: [],
        rangeLabel: [this.min, this.max],
        chartWidth: 250,
        centralLabel: `${this.value}`
      }

      let arcDelimiters = []
      if (this.options.arcDelimiters) {
        arcDelimiters = this.options.arcDelimiters.map((item) => {
          return (item * 100) / (this.max - this.min)
        })
      }
      return {...defaultConfig, ...this.options, arcDelimiters}
    }
  },
  watch: {
    value() {
      this.updateChart()
    },
    options: {
      handler() {
        this.updateChart()
      },
      deep: true
    }
  },
  methods: {
    updateDimension() {
      if (this.$refs.wrapper) {
        this.wrapperWidth = this.$refs.wrapper.offsetWidth
        this.updateChart()
      }
    },
    updateChart() {
      this.gaugeChart.removeGauge()
      this.renderChart()
    },
    renderChart() {
      this.gaugeChart = GaugeChart.gaugeChart(this.$refs.chart, this.chartWidth, this.config)
      this.gaugeChart.updateNeedle(this.needleValue)
    }
  }
}
</script>
