<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('dashboard.UVPrediction') }}</v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <auto-complete
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :select="workingSiteSelect"
            :label="$t('workingSites.workingSite')"
            search-form
            @change="setLatLng"
            @initialize="setLatLng"
          />
          <date-picker v-model="search.dateTime" search-form :label="$t('shared.createdMin')" />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="filtering" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <VChart type="line" class="mb-5" :data="chartData" :options="chartOptions" ref="chart" />
    </v-card-text>
  </v-card>
</template>

<script>
import VChart from '@/components/Charts/VChart'
import moment from 'moment-jalaali'
import {today} from '@/lib/utils'
export default {
  name: 'Dashboard',
  components: {
    VChart
  },
  data() {
    return {
      search: {
        workingSiteId: null,
        dateTime: today(),
        lat: null,
        lon: null
      },
      chartData: {
        labels: [],
        datasets: [
          {
            active: true,
            borderColor: 'rgb(75, 192, 192)',
            value: 'UVPrediction',
            label: this.$t('dashboard.UVPrediction'),
            backgroundColor: '#087979',
            data: []
          }
        ]
      },
      chartOptions: {
        tension: 0.25,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                scaleID: 'y',
                borderColor: 'blue',
                backgroundColor: 'blue',
                borderDash: [6, 6],
                borderDashOffset: 0,
                label: {
                  enabled: true,
                  yAdjust: -12,
                  position: 'center',
                  color: 'blue',
                  backgroundColor: 'transparent',
                  // borderRadius: 5,
                  content: (ctx) => 'Max Value: ' + +this.getYMax(ctx).toFixed(3),
                  rotation: 'auto'
                },
                value: (...arg) => this.getYMax(...arg)
              },
              {
                type: 'box',
                backgroundColor: 'rgba(39, 245, 109, 0.28)',
                borderWidth: 0,
                yMax: 2.5,
                yMin: 0,
                label: {
                  drawTime: 'afterDraw',
                  display: true,
                  content: 'Low',
                  position: {
                    x: 'center',
                    y: 'center'
                  }
                }
              },
              {
                type: 'box',
                backgroundColor: 'rgba(235, 245, 39, 0.28)',
                borderWidth: 0,
                yMax: 5.5,
                yMin: 2.5,
                label: {
                  drawTime: 'afterDraw',
                  display: true,
                  content: 'Moderate',
                  position: {
                    x: 'center',
                    y: 'start'
                  }
                }
              },
              {
                type: 'box',
                backgroundColor: 'rgba(255, 163, 31, 0.25)',
                borderWidth: 0,
                yMax: 7.5,
                yMin: 5.5,
                label: {
                  drawTime: 'afterDraw',
                  display: true,
                  content: 'High',
                  position: {
                    x: 'center',
                    y: 'start'
                  }
                }
              },
              {
                type: 'box',
                backgroundColor: 'rgba(242, 71, 71, 0.28)',
                borderWidth: 0,
                yMax: 10.5,
                yMin: 7.5,
                label: {
                  drawTime: 'afterDraw',
                  display: true,
                  content: 'Very High',
                  position: {
                    x: 'center',
                    y: 'start'
                  }
                }
              },
              {
                type: 'box',
                backgroundColor: 'rgba(137, 38, 205, 0.28)',
                borderWidth: 0,
                yMax: 16,
                yMin: 10.5,
                label: {
                  drawTime: 'afterDraw',
                  display: true,
                  content: 'Extreme',
                  position: {
                    x: 'center',
                    y: 'start'
                  }
                }
              }
            ]
          }
        }
      }
    }
  },
  methods: {
    setLatLng(workingSite) {
      console.log(workingSite)
      if (workingSite) {
        this.search.lat = workingSite.latitude
        this.search.lon = workingSite.longitude
        if (this.search.dateTime) {
          this.filtering()
        }
      } else {
        this.search.lon = null
        this.search.lat = null
      }
    },
    workingSiteSearch(filter) {
      return this.$api.org.workingSites.getAllInfo(filter)
    },
    async workingSiteSelect() {
      const filter = {
        page: 1,
        size: 1
      }
      const res = await this.$api.org.workingSites.getAllInfo(filter)
      const ws = res[0]
      if (ws) {
        this.search.workingSiteId = ws.id
      }
      return ws
    },
    async filtering() {
      this.progressing = true
      const filter = this.$filter()
      try {
        const res = await this.$api.org.reports.getGlobalUv(filter)
        if (!res) return
        this.chartData.labels = res.result.map((item) => moment.utc(item.uv_time).format('HH:mm'))
        this.chartData.datasets[0].data = res.result.map((item) => item.uv)
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    getYMax(ctx) {
      const maxes = ctx.chart.data.datasets.map((dataset) => {
        return Math.max(...dataset.data)
      })
      return Math.max(...maxes)
    }
  }
}
</script>
