<template>
  <v-card>
    <v-card-title class="text-h5">{{ $t('dashboard.onlineUsers') }}</v-card-title>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <auto-complete
            v-model="search.userId"
            item-text="fullName"
            :search="userSearch"
            :label="$t('users.user')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getItems" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>
    </v-card-text>

    <v-data-table
      :items="items"
      :headers="headers"
      :server-items-length="count"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip v-if="$hasPermission('org.identity.roles')" top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="warning" :to="`/org/users/${item.userInfo.id}/roles`" v-on="on">
                <v-icon> mdi-account-key </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.permissions') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.image`]="{item}">
        <Thumbnail v-if="item.userInfo.image" :src="item.userInfo.image" width="40" height="40" />
      </template>

      <template #[`item.id`]="{item}">
        {{ item.userInfo.id }}
      </template>

      <template #[`item.mobile`]="{item}">
        {{ item.userInfo.mobile }}
      </template>

      <template #[`item.online`]="{item}">
        <v-icon v-if="item.online" color="success" small> mdi-circle</v-icon>
        <v-icon v-else color="error" small> mdi-circle</v-icon>
      </template>

      <template #[`item.fullName`]="{item}"> {{ item.userInfo.firstName }} {{ item.userInfo.lastName }} </template>

      <template #[`item.maxHum`]="{item}">
        <v-chip color="warning" label text-color="white" small>
          <v-icon small class="me-1"> mdi-water </v-icon>
          {{ item.maxHum }}
        </v-chip>
      </template>

      <template #[`item.maxTemp`]="{item}">
        <v-chip color="success" label text-color="white" small>
          <v-icon small class="me-1"> mdi-thermometer-lines </v-icon>
          {{ item.maxTemp }}
        </v-chip>
      </template>

      <template #[`item.maxUV`]="{item}">
        <v-chip color="error" label text-color="white" small>
          <v-icon small class="me-1"> mdi-white-balance-sunny </v-icon>
          {{ item.maxUV }}
        </v-chip>
      </template>

      <template #[`item.maxUVDose`]="{item}">
        <v-chip label small> {{ item.maxUVDose }}% </v-chip>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'Index',
  props: {
    workingSiteId: {type: [String, Number], default: null}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      timer: null,
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: this.$t('shared.online'), value: 'online', sortable: false},
        {text: this.$t('shared.image'), value: 'image', sortable: false},
        {text: this.$t('shared.fullName'), value: 'fullName', sortable: false},
        {text: this.$t('dashboard.lastUV'), value: 'maxUV', sortable: false},
        {text: this.$t('dashboard.lastUVDose'), value: 'maxUVDose', sortable: false},
        {text: this.$t('dashboard.lastTemp'), value: 'maxTemp', sortable: false},
        {text: this.$t('dashboard.lastHum'), value: 'maxHum', sortable: false},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      items: [],
      search: {
        userId: null,
        organizationId: this.organizationId || null,
        workingSiteId: this.workingSiteId || null
      }
    }
  },
  created() {
    this.startIntervalFetching()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    startIntervalFetching() {
      this.getItems()
      this.timer = setInterval(() => {
        this.getItems()
      }, 5000)
    },
    workingSiteSearch(filter) {
      return this.$api.org.workingSites.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.org.users.getAllInfo(filter)
    },
    async getItems() {
      const filter = this.$filter()
      try {
        this.progressing = true
        const res = await this.$api.org.reports.getOnlineUsers(filter)
        this.items = res
        this.progressing = false
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
