<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('dashboard.heatmap') }}</v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <date-picker v-model="search.createdMin" search-form :label="$t('shared.createdMin')" />
          <date-picker v-model="search.createdMax" search-form :label="$t('shared.createdMax')" />

          <auto-complete
            v-if="!organizationId"
            v-model="search.organizationId"
            :search="organizationSearch"
            :label="$t('organizations.organization')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :select="workingSiteSelect"
            @initialize="getData"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getData" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <GeopointEditor ref="map" :heatmap="heatmap" height="900" />
    </v-card-text>
  </v-card>
</template>

<script>
import GeopointEditor from '@/components/shared/GeopointEditor'
export default {
  name: 'Heatmap',
  components: {
    GeopointEditor
  },
  props: {
    organizationId: {type: [Number, String], default: null},
    workingSiteId: {type: [Number, String], default: null}
  },
  data() {
    return {
      coordinate: null,
      search: {
        createdMax: '2023-01-01T00:00:00',
        createdMin: '2021-01-01T00:00:00',
        timeGroupBy: 'MONTHLY',
        organizationId: this.organizationId || null,
        workingSiteId: this.workingSiteId || null
      },
      heatmap: [],
      columns: [
        {field: 'option', title: this.$t('dashboard.title')},
        {field: 'percent', title: this.$t('dashboard.percent')}
      ]
    }
  },
  methods: {
    async workingSiteSelect() {
      const filter = {
        page: 1,
        size: 1
      }
      const res = await this.$api.workingSites.getAllInfo(filter)
      const ws = res[0]
      if (ws) {
        this.search.workingSiteId = ws.id
      }
      return ws
    },
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    getData() {
      this.progressing = true
      const filter = this.$filter()
      return this.$api.reports.getHeatmap(filter).then((res) => {
        const max = Math.max(...res.map((item) => item.countPoint))
        this.heatmap = res.map((item) => [item.latitude, item.longitude, item.countPoint / max])
        const bounds = res.map((item) => ({lat: item.latitude, lng: item.longitude}))
        if (!this.$isEmpty(this.heatmap)) {
          this.$nextTick(() => {
            this.$refs.map.setBounds(bounds)
          })
        } else if (this.coordinate) {
          this.$refs.map.setCenter(this.coordinate)
        }
        this.progressing = false
      })
    }
  }
}
</script>
