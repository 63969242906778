<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('dashboard.onlineUv') }}</v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <auto-complete
            v-if="!organizationId"
            v-model="search.organizationId"
            :search="organizationSearch"
            :label="$t('organizations.organization')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getData" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <div class="text-center">
        <VueGauge ref="chart" :value="uvAverage" :options="options" :min="0" :max="20" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueGauge from '@/components/Charts/VueGauge'
export default {
  name: 'OnlineUv',
  components: {
    VueGauge
  },
  props: {
    organizationId: {type: [Number, String], default: null},
    workingSiteId: {type: [Number, String], default: null},
    userId: {type: [Number, String], default: null}
  },
  data() {
    return {
      uvAverage: 0,
      search: {
        createdMax: '2023-01-01T00:00:00',
        createdMin: '2021-01-01T00:00:00',
        userId: this.userId || null,
        organizationId: this.organizationId || null,
        workingSiteId: this.workingSiteId || null
      },
      options: {
        chartWidth: 700,
        hasNeedle: true,
        needleColor: 'black',
        arcColors: ['rgba(39, 245, 109)', 'rgba(235, 245, 39)', 'rgba(255, 163, 31)', 'rgba(242, 71, 71)', 'rgba(137, 38, 205)'],
        arcDelimiters: [2.5, 5.5, 7.5, 10.5],
        arcLabels: [2.5, 5.5, 7.5, 10.5],
        arcOverEffect: false,
        labelsFont: 'IRANSans',
        rangeLabel: ['0', '20'],
        needleStartValue: 0
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    async getData() {
      this.progressing = true
      const filter = this.$filter()
      try {
        const res = await this.$api.reports.getOnlineUsersAverage(filter)
        this.uvAverage = res.averageUv
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
